import { render, staticRenderFns } from "./ContratoTarifario.vue?vue&type=template&id=3f88588d&"
import script from "./ContratoTarifario.vue?vue&type=script&lang=js&"
export * from "./ContratoTarifario.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports