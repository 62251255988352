<template>
  <div>
    <section class="content-header">
      <div class="btn btn-group">
        <button
          type="button"
          class="btn btn-sm btn-info"
          data-toggle="modal"
          data-target="#modal-tarifarios"
          v-if="
            $store.getters.can('hidrocarburos.contratos.edit') &&
              asignarTarifarios.length > 0
          "
        >
          Agregar Tarifario
        </button>
      </div>
    </section>
    <section id="table">
      <div class="col-md-12 form-group">
        <table
          id="tabla-tarifas"
          class="table table-bordered table-striped table-hover table-sm"
        >
          <thead class="thead-dark">
            <tr>
              <th># Contrato</th>
              <th>Vigencia Contrato</th>
              <th>Tarifario</th>
              <th>Vigencia Tarifario</th>
              <th class="text-center">Aprob. Transportadora</th>
              <th class="text-center">Acciones</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(contrato, contrato_index) in contratoTarifarios"
              :key="contrato_index"
            >
              <td>{{ contrato.no_contrato }}</td>
              <td class="text-center">
                <span class="badge bg-navy">
                  {{ contrato.contrato_fecha_inicial }} |
                  {{ contrato.contrato_fecha_final }}
                </span>
              </td>
              <td class="text-nowrap">{{ contrato.tarifario_nombre }}</td>
              <td class="text-center">
                <span class="badge bg-navy">
                  {{ contrato.tarifario_fecha_inicial }} |
                  {{ contrato.tarifario_fecha_final }}
                </span>
              </td>
              <td class="text-center">
                <span
                  class="badge"
                  v-bind:class="[
                    contrato.transportadora_sign.length > 0
                      ? 'badge-success'
                      : 'badge-danger',
                  ]"
                >
                  {{ contrato.transportadora_sign.length > 0 ? "Si" : "No" }}
                </span>
              </td>
              <td class="text-center">
                <div class="btn btn-group">
                  <button
                    type="button"
                    class="btn btn-sm bg-navy"
                    data-toggle="modal"
                    data-target="#modal-form-adjunto-trifario"
                    v-if="
                      !contrato.link_documento &&
                        $store.getters.can(
                          'hidrocarburos.contratos.saveDocumentoTarifario'
                        )
                    "
                    @click="instancia(contrato.tarifario_id)"
                  >
                    <i class="fa fa-upload"></i>
                  </button>
                  <a
                    target="_blank"
                    class="btn btn-info btn-sm"
                    v-if="contrato.link_documento"
                    :href="uri_docs + contrato.link_documento"
                  >
                    <i class="fa fa-download"></i>
                  </a>
                  <button
                    type="button"
                    class="btn btn-sm btn-success"
                    data-toggle="modal"
                    data-target="#modal-form-firmas"
                    :disabled="!contrato.link_documento"
                    @click="
                      getFirmas(
                        contrato.contrato_id,
                        contrato.tarifario_id,
                        contrato.contrato_has_tarifario_id,
                        contrato.transportadora_sign
                      )
                    "
                  >
                    Firmar
                  </button>
                  <button
                    type="button"
                    class="btn btn-sm bg-info"
                    data-toggle="modal"
                    data-target="#modal-form-tarifas"
                    @click="getTarifas(contrato.tarifas)"
                  >
                    <i class="fas fa-eye"></i>
                  </button>
                  <button
                    type="button"
                    class="btn btn-sm bg-olive"
                    @click="recordarFrima(contrato)"
                  >
                    <i class="fas fa-envelope"></i>
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </section>

    <!-- Modal de Adjunto Tarifario  -->
    <div class="modal fade" id="modal-form-adjunto-trifario">
      <div class="modal-dialog modal-md">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">Adjunto Archivo</h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModalArchivo"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="form-group" v-if="!contratoTarifarios.link_documento">
                <input
                  type="file"
                  id="archivo-adjunto-tarifario"
                  class="form-control-file"
                  accept="image/x-png,image/gif,image/jpeg,application/pdf,"
                  @change="obtenerArchivo"
                />
              </div>
              <div class="form-group float-right" v-if="fileAdjunto">
                <button
                  type="button"
                  class="btn btn-secondary col-md-12"
                  @click="limpiarArchivo"
                >
                  <i class="fas fa-eraser"></i>
                </button>
              </div>
            </div>
          </div>
          <div class="modal-footer justify-content-between">
            <button
              type="button"
              class="btn btn-primary"
              v-if="fileAdjunto"
              @click="saveArchivo()"
            >
              Guardar
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal de Aprobación de tarifarios  -->
    <div class="modal fade" id="modal-form-firmas">
      <div class="modal-dialog modal-md">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">Aprobación Tarifa</h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModal"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <!--- Firma Transportadora --->
              <div class="form-group col-md-12">
                <h5>Firma Transportadora</h5>
                <!-- Formulario para firmar -->
                <div
                  class="row"
                  v-if="
                    !firmas.transportadoraFlag.firmado &&
                      firmas.transportadoraFlag.permiso
                  "
                >
                  <div class="form-group col-md-12">
                    <div class="callout callout-warning">
                      <small
                        ><strong
                          >Para firmar la concilicación por favor digite su
                          clave.</strong
                        ></small
                      >
                    </div>
                  </div>
                  <div class="form-group col-md-6">
                    <label for="sign_password">Contraseña</label>
                    <input
                      type="password"
                      class="form-control form-control-sm"
                      v-model="firmas.transportadoraForm.pass"
                      :class="
                        $v.firmas.transportadoraForm.pass.$invalid
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                    />
                  </div>
                  <div class="form-group col-md-6">
                    <label for="re_sign_password">Confirme Contraseña</label>
                    <input
                      type="password"
                      class="form-control form-control-sm"
                      v-model="firmas.transportadoraForm.re_pass"
                      :class="
                        $v.firmas.transportadoraForm.re_pass.$invalid
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                    />
                  </div>
                  <div class="form-group col-md-12">
                    <label for="observaciones">Observaciones</label
                    ><textarea
                      vtype="date"
                      rows="2"
                      class="form-control form-control-sm"
                      v-model="firmas.transportadoraForm.observacion"
                      :class="
                        $v.firmas.transportadoraForm.observacion.$invalid
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                    ></textarea>
                  </div>
                  <div class="form-group col-md-12">
                    <button
                      type="button"
                      v-show="!$v.firmas.transportadoraForm.$invalid"
                      title="Guardar firma"
                      class="btn btn-sm bg-success"
                      data-toggle="tooltip"
                      v-if="firmas.transportadoraFlag.permiso"
                      @click="saveFirma(firmas.transportadoraForm)"
                    >
                      <i class="fas fa-signature"></i>
                      <div>
                        <span><small>Aprobar Tarifa</small></span>
                      </div>
                    </button>
                  </div>
                </div>
                <!-- Información de la firma -->
                <div class="row" v-if="firmas.transportadoraFlag.firmado">
                  <div class="form-group col-md-12">
                    <p>
                      Firmado por: <br />
                      <strong>{{
                        firmas.transportadoraFlag.data.usuario
                      }}</strong>
                    </p>
                    <p>
                      Fecha: <br />
                      <strong>{{
                        firmas.transportadoraFlag.data.fecha
                      }}</strong>
                    </p>
                    <p>
                      Observación: <br />
                      {{ firmas.transportadoraFlag.data.observacion }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal de vista de tarifas  -->
    <div class="modal fade" id="modal-form-tarifas">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">Tarifas</h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModal"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <table class="table table-sm table-hover">
                <thead class="thead-dark">
                  <tr>
                    <th>Tarifa</th>
                    <th>Valor</th>
                    <th>Vigencia</th>
                    <th>Producto Líquido</th>
                    <th>Tipo Vehículo</th>
                    <th>Ruta</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="tarifa in listaTarifas" :key="tarifa.id">
                    <td>{{ tarifa.nombre }}</td>
                    <td>{{ tarifa.tarifa }}</td>
                    <td>{{ tarifa.vigencia }}</td>
                    <td>{{ tarifa.producto_liquido }}</td>
                    <td>{{ tarifa.tipo_vehiculo }}</td>
                    <td>{{ tarifa.ruta }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              data-dismiss="modal"
              class="btn btn-secondary"
            >
              Cerrar
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal de asignación de tarifarios  -->
    <div class="modal fade" id="modal-tarifarios">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">Asignar Tarifario</h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModal"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <!-- select tarifario -->
              <div class="form-group col-md-12">
                <label for="fecha_final">Tarifario</label>
                <v-select
                  :class="[
                    $v.formTarifario.tarifario.$invalid
                      ? 'is-invalid'
                      : 'is-valid',
                    $store.getters.getDarkMode ? 'dark-vselect' : '',
                  ]"
                  v-model="formTarifario.tarifario"
                  placeholder="Tarifario"
                  label="nombre"
                  class="form-control form-control-sm p-0"
                  :options="asignarTarifarios"
                  :filterable="true"
                ></v-select>
              </div>
              <!-- File adjunto -->
              <div
                class="form-group col-md-12"
                v-if="!formTarifario.link_documento"
              >
                <label>Adjunto</label>
                <input
                  type="file"
                  id="archivo-adjunto-tarifario-1"
                  class="form-control-file"
                  accept="image/x-png,image/gif,image/jpeg,application/pdf,"
                  @change="obtenerArchivo"
                />
              </div>
            </div>
          </div>
          <div class="modal-footer justify-content-between">
            <button
              type="button"
              class="btn btn-primary"
              v-if="
                $store.getters.can('hidrocarburos.contratos.edit') &&
                  !$v.formTarifario.$invalid &&
                  fileAdjunto
              "
              @click="asignarTarifario()"
            >
              Asignar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import vSelect from "vue-select";
import { required, sameAs } from "vuelidate/lib/validators";
import $ from "jquery";

export default {
  name: "ContratoTarifario",
  components: {
    vSelect,
  },
  data() {
    return {
      contrato_id: null,
      tarifario_id: null,
      contratoTarifarios: [],
      asignarTarifarios: [],
      listaTarifas: [],
      fileAdjunto: null,
      archivoValido: true,
      firmas: {
        transportadoraFlag: {
          firmado: false,
          permiso: false,
          data: null,
        },
        transportadoraForm: {
          tipo_aprobacion: "transportadora",
          observacion: null,
          pass: null,
          re_pass: null,
        },
      },
      formTarifario: {
        tarifario: null,
      },
      uri_docs: process.env.VUE_APP_AWS_BUCKET_URL,
    };
  },
  validations: {
    formTarifario: {
      tarifario: {
        required,
      },
    },
    firmas: {
      transportadoraForm: {
        pass: {
          required,
        },
        re_pass: {
          required,
          sameAsPassword: sameAs("pass"),
        },
        observacion: {
          required,
        },
      },
    },
  },
  methods: {
    async getIndex() {
      this.contrato_id = this.$parent.id;
      this.estado;
      await axios
        .get("/api/hidrocarburos/contratos/show/" + this.contrato_id)
        .then((response) => {
          this.getTableData(response.data);
        });
      this.getTarifarios();
    },

    getTableData(contratos) {
      this.contratoTarifarios = {};
      let data = [];
      contratos.forEach((contrato) => {
        contrato.tarifarios.forEach((tarifario) => {
          let transportadora_sign = [];
          contrato.aprobaciones.forEach((aprobacion) => {
            if (tarifario.pivot.id == aprobacion.contrato_has_tarifario_id) {
              switch (aprobacion.tipo_aprobacion) {
                case "transportadora":
                  if (
                    aprobacion.contrato_id == contrato.id &&
                    aprobacion.tarifario_id == tarifario.id
                  ) {
                    transportadora_sign.push(aprobacion);
                  }
                  break;
              }
            }
          });
          data.push({
            contrato_id: contrato.id,
            tarifario_id: tarifario.id,
            no_contrato: contrato.no_contrato,
            contrato_fecha_inicial: contrato.fecha_ini,
            contrato_fecha_final: contrato.fecha_fin,
            tarifario_nombre: tarifario.nombre,
            tarifario_fecha_inicial: tarifario.fecha_inicio,
            tarifario_fecha_final: tarifario.fecha_final,
            transportadora_sign,
            tarifas: tarifario.tarifas,
            contrato_has_tarifario_id: tarifario.pivot.id,
            link_documento: tarifario.pivot.link_documento,
          });
        });
      });
      this.contratoTarifarios = data;
    },

    getTarifas(tarifas) {
      this.listaTarifas = [];
      tarifas.forEach((tarifa) => {
        let producto_liquido = "";
        if (tarifa.producto_liquido) {
          producto_liquido = tarifa.producto_liquido.nombre;
        }

        let tipo_vehiculo = "";
        if (tarifa.tipo_vehiculo) {
          tipo_vehiculo = tarifa.tipo_vehiculo.nombre;
        }

        let ruta = "";
        if (tarifa.ruta) {
          ruta = tarifa.ruta.nombre;
        }

        this.listaTarifas.push({
          nombre: tarifa.nombre,
          tarifa: tarifa.detalles_tarifa[0].tarifa,
          vigencia: `${tarifa.detalles_tarifa[0].fecha_inicio_vigencia} | ${tarifa.detalles_tarifa[0].fecha_fin_vigencia}`,
          producto_liquido,
          tipo_vehiculo,
          ruta,
        });
      });
    },

    getFirmas(
      contrato_id,
      tarifario_id,
      contrato_has_tarifario_id,
      aprobacionTransportadora
    ) {
      const permisos_firmas = this.$parent.listasForms.permisos_firmas;

      /***** TRANSPORTADORA *****/
      // Se valida si ya la transportadora autorizó la tarifa
      if (aprobacionTransportadora.length > 0) {
        let array = aprobacionTransportadora[0];
        this.firmas.transportadoraFlag.firmado = true;
        this.firmas.transportadoraFlag.data = {
          usuario: array.user.name,
          fecha: array.fecha_aprobacion,
          observacion: array.observacion,
        };
      } else {
        this.firmas.transportadoraFlag.firmado = false;
        // Se pregunta si el usuario logueado tiene permisos de firmar
        if (
          this.$store.getters.can("hidrocarburos.contratos.firmaTransportadora")
        ) {
          this.firmas.transportadoraFlag.permiso = true;
          this.firmas.transportadoraForm.contrato_id = contrato_id;
          this.firmas.transportadoraForm.tarifario_id = tarifario_id;
          this.firmas.transportadoraForm.contrato_has_tarifario_id = contrato_has_tarifario_id;
        } else {
          this.firmas.transportadoraFlag.permiso = false;
        }
      }
    },

    instancia(tarifario_id) {
      this.tarifario_id = tarifario_id;
    },

    limpiarArchivo() {
      this.fileAdjunto = null;
      document.getElementById("archivo-adjunto-tarifario").value = "";
      document.getElementById("archivo-adjunto-tarifario-1").value = "";
    },

    obtenerArchivo(e) {
      this.fileAdjunto = null;
      this.archivoValido = false;
      if (e.target.files[0]) {
        this.cargando = true;
        const file = e.target.files[0];
        if (this.validaArchivo(file)) {
          this.archivoValido = true;
          this.fileAdjunto = file;
        }
      } else {
        this.archivoValido = true;
      }
    },

    validaArchivo(file) {
      let flag = true;
      if (file.size > 1000000) {
        flag = false;
        this.limpiarArchivo();
        this.$swal({
          icon: "error",
          title: `El Archivo es muy pesado para subirlo, seleccione un archivo de nuevo...`,
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
      }
      return flag;
    },

    saveArchivo() {
      this.cargando = true;
      let me = this;
      let formData = new FormData();
      formData.append("contrato_id", this.contrato_id);
      formData.append("tarifario_id", this.tarifario_id);
      formData.append("link_documento", this.fileAdjunto);
      axios
        .post(
          "/api/hidrocarburos/tarifariocontratos/saveDocumentoTarifario",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((response) => {
          this.getIndex();
          this.$refs.closeModalArchivo.click();
          this.limpiarArchivo();
          this.$swal({
            icon: "success",
            title: "El archivo se guardó exitosamente...",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.archivoValido = false;
          (this.fileAdjunto = null),
            (this.link_documento = response.data.link_documento);
          this.cargando = false;
        })
        .catch(function(error) {
          me.$swal({
            icon: "error",
            title: "Ocurrió un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    saveFirma(array) {
      let me = this;
      if (!this.$v.firmas.transportadoraForm.$invalid) {
        let pass = array.pass;
        let data = {
          contrato_id: array.contrato_id,
          tarifario_id: array.tarifario_id,
          contrato_has_tarifario_id: array.contrato_has_tarifario_id,
          observacion: array.observacion,
          tipo_aprobacion: array.tipo_aprobacion,
          user_id: this.$store.getters.getUser.id,
        };
        let form = { data, pass };

        // Se valida la url para hacer la firma
        let url = null;
        switch (array.tipo_aprobacion) {
          case "transportadora":
            url = "/api/hidrocarburos/tarifariocontratos/firmaTransportadora";
            break;
        }

        axios({
          method: "PUT",
          url,
          data: form,
        })
          .then((response) => {
            me.$parent.form.estado = response.data.estado_contrato;
            me.$parent.estado = response.data.estado_contrato;
            me.resetForm();
            me.getIndex();
            $("#modal-form-firmas").hide();
            $("body").removeClass("modal-open");
            $(".modal-backdrop").remove();

            me.$swal({
              icon: "success",
              title: "La tarifa fue autorizada exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });

            const mensaje_correo = response.data.envio_correo;
            if (mensaje_correo.msg) {
              me.$swal({
                icon: "success",
                title: mensaje_correo.msg,
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            }
            if (mensaje_correo.error) {
              me.$swal({
                icon: "error",
                title: "Ocurrió un error: " + mensaje_correo.error,
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            }
          })
          .catch((e) => {
            me.$swal({
              icon: "error",
              title: "Ocurrió un error: " + e.response.data.msg,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    resetForm() {
      this.firmas = {
        transportadoraFlag: {
          firmado: false,
          permiso: false,
          data: null,
        },
        transportadoraForm: {
          tipo_aprobacion: "transportadora",
          observacion: null,
          pass: null,
          re_pass: null,
        },
      };
    },

    recordarFrima(tarifario) {
      this.$swal({
        title: "Esta seguro de enviar este Recordatorio?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Enviar!",
      }).then((result) => {
        if (result.value) {
          let me = this;
          const data = {
            contrato_id: tarifario.contrato_id,
            tarifario_id: tarifario.tarifario_id,
          };

          axios
            .post("/api/hidrocarburos/tarifarios/emailRecordarorioFirma", {
              data: data,
            })
            .then((response) => {
              const mensaje_correo = response.data;
              if (mensaje_correo.msg) {
                me.$swal({
                  icon: "success",
                  title: mensaje_correo.msg,
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000,
                  timerProgressBar: true,
                });
              }
              if (mensaje_correo.error) {
                me.$swal({
                  icon: "error",
                  title: "Ocurrió un error: " + mensaje_correo.error,
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000,
                  timerProgressBar: true,
                });
              }
            })
            .catch(function(error) {
              me.$swal({
                icon: "error",
                title: "Ocurrió un error" + error,
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            });
        }
      });
    },

    getTarifarios() {
      let me = this;
      axios
        .get("/api/hidrocarburos/contratos/getTarifarios/" + this.contrato_id)
        .then((response) => {
          // this.asignarTarifarios = response.data;
          response.data.forEach(function(element) {
            if (element.aprobacion_tarifarios.length == 5) {
              me.asignarTarifarios.push({
                id: element.tarifario.id,
                nombre: element.tarifario.nombre,
              });
            }
          });
        });
    },

    asignarTarifario() {
      if (!this.$v.formTarifario.$invalid) {
        this.cargando = true;

        // Se asignan los valores para el formulario a enviar
        let formData = new FormData();
        formData.append("contrato_id", this.contrato_id);
        formData.append("tarifario_id", this.formTarifario.tarifario.id);
        formData.append("link_documento", this.fileAdjunto);

        axios({
          method: "POST",
          url: "/api/hidrocarburos/contratos/asignarTarifario",
          data: formData,
        })
          .then((res) => {
            this.cargando = false;
            if (res.data.status == 0) {
              this.$swal({
                icon: "error",
                title: res.data.msg,
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            } else {
              this.$refs.closeModal.click();
              this.formTarifario.tarifario = null;
              this.limpiarArchivo();
              this.$swal({
                icon: "success",
                title: "La detalle de la tarifa se guardó exitosamente...!",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 4000,
                timerProgressBar: true,
              });
              this.getIndex();
              this.cargando = false;
            }
          })
          .catch((e) => {
            this.$swal({
              icon: "error",
              title:
                "Ha ocurrido un error, por favor intente la acción nuevamente." +
                e,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            this.cargando = false;
          });
      }
    },
  },

  mounted() {
    this.getIndex();
  },
};
</script>
